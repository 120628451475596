/* You can add global styles to this file, and also import other style files */
// body {
//   background-color: #f5f5f5;
// }

// dashboard-root {
//   display: block;
//   min-height: 100vh;
// }

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
